import React from "react"
import Back from "../common/back/Back"
import "./contact.css"

const Contact = () => {
  const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3233.592736174262!2d128.48844054695965!3d35.85898868192271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3565e55971fe5d2d%3A0xcdc738aa26b24ec1!2z64yA6rWs6rSR7Jet7IucIOuLrOyEnOq1rCDri6zshJzrjIDroZwgNjc1!5e0!3m2!1sko!2skr!4v1711455660602!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" '
  return (
    <>
      <Back title='Contact us' />
      <section className='contacts padding'>
        <div className='container shadow flexSB'>
          <div className='left row'>
            <iframe src={map}></iframe>
          </div>
          <div className='right row'>
            <h1>문의하기</h1>
            <p>우리는 어떤 제안이나 대화에도 열려있습니다.</p>

            <div className='items grid2'>
              <div className='box'>
                <h4>주소:</h4>
                <p>대구광역시 달서구<br />달서대로 675<br />계명대학교 산학협력단</p>
              </div>
              <div className='box'>
                <h4>이메일:</h4>
                <p>contact@koreaclass.com</p>
              </div>
              <div className='box'>
                <h4>연락처:</h4>
                <p>053)274-8254</p>
              </div>
            </div>

            <form action=''>
              <div className='flexSB'>
                <input type='text' placeholder='성명' />
                <input type='email' placeholder='이메일' />
              </div>
              <input type='text' placeholder='제목' />
              <textarea cols='30' rows='10' placeholder="여기에 내용을 작성하세요...">
              </textarea>
              <button className='primary-btn'>메세지 보내기</button>
            </form>

            <h3>당신의 연락을 기다리고 있습니다!</h3>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
