import React from "react"
import { blog } from "../../../dummydata"
import "./footer.css"

const Footer = () => {
  return (
    <>
      <section className='newletter'>
        <div className='container flexSB'>
          <div className='left row'>
            <h1>뉴스레터 - 최신 업데이트를 받아보세요.</h1>
            <span>최신 업데이트를 받아보고 계속해서 참여해주세요!</span>
          </div>
          <div className='right row'>
            <input type='text' placeholder='이메일 주소를 입력해 주세요.' />
            <i className='fa fa-paper-plane'></i>
          </div>
        </div>
      </section>
      <footer>
        <div className='container padding' style={{paddingTop:"40px", paddingBottom:"0px"}}>
          <div className='box logo'>
            <h1>KOREACLASS</h1>
            <span>Specialist in VR/AR Technology</span>
          </div>
          <div className='box last'>
              <div className='items flexSB'>
                <div className='text'>
                  <span>
                  <i className='fa fa-paper-plane'></i>
                  </span>
                  <h4>contact@koreaclass.com</h4>
                </div>
              </div>
          </div>
          <div className='box last'>
              <div className='items flexSB'>
                <div className='text'>
                  <span>
                  <i className='fa fa-phone-alt'></i>
                  </span>
                  <h4>053)274-8254</h4>
                </div>
              </div>
          </div>
          <div className='box last'>
              <div className='items flexSB'>
                <div className='text'>
                  <span>
                  <i className='fa fa-map'></i>
                  </span>
                  <h4>대구시 달서구 달서대로 675 계명대학교 산학협력단</h4>
                </div>
              </div>
          </div>
          <div className='box last' style={{marginTop:"-20px"}}>
              <div className='items flexSB'>
                <div className='text'>
                  <span>
                  <i className='fab fa-facebook-f icon'></i>
                  <i className='fab fa-twitter icon'></i>
                  <i className='fab fa-instagram icon'></i>
                  <i className='fab fa-youtube icon'></i>
                  </span>
                  <h4>소셜 네트워크 링크</h4>
                </div>
              </div>
          </div>
        </div>
      </footer>
      <div className='legal' style={{paddingTop:"20px", paddingBottom:"40px"}}>
        <p>
          Copyright 2023. KOREACLASS Co. All rights reserved.
        </p>
      </div>
    </>
  )
}

export default Footer
