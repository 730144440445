import React from "react"
import Heading from "../../common/heading/Heading"
import { Link } from "react-router-dom"
import "./Hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='row'>
            <Heading subtitle='WELCOME TO KOREACLASS' title='고객가치 실현의 기술과 창의성' />
            <p>새로운 세계를 탐험하며 현실을 뛰어넘는 경험을 제공합니다.<br/>현실과 가상이 만나는 곳, 지역의 아름다움을 새롭게 경험하세요.</p>
            <div className='button'>
              <Link to='/contact'>
                <button className='primary-btn'>
                문의하기 <i className='fa fa-long-arrow-alt-right'></i>
                </button>
              </Link>
              <Link to='/demo'>
                <button>
                데모 사이트 <i className='fa fa-long-arrow-alt-right'></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className='margin'></div>
    </>
  )
}

export default Hero
