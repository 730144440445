import React from "react"
import Back from "../common/back/Back"
import PriceCard from "./PriceCard"
import "./price.css"
import Faq from "./Faq"

const Pricing = () => {
  return (
    <>
      <Back title='Choose The Right Plan' />
      <section className='price padding'>
        <div className='container grid'>
          <PriceCard />
        </div>
        <div className='container' style={{marginTop:"40px", display:"flex",flexDirection:"column" , alignItems:"center", background:"#fff", padding:"10px", opacity:1}}>
          <p style={{margin:"5px", background:"#fff", fontSize:"1.5rem", fontWeight:700}}>추가 비용</p>
          <p style={{margin:"5px", background:"#fff"}}>💡 VR영상 촬영비: 33,000원/평당(VAT 포함가)</p>
          <p style={{margin:"5px", background:"#fff"}}>💡 호스팅 운영비(50평 이하): 110,000원/월(VAT 포함가)</p>
          <p style={{margin:"5px", background:"#fff"}}>💡 호스팅 운영비(50평 초과): 220,000원/월(VAT 포함가)</p>
          <p style={{margin:"5px", background:"#fff"}}>💡 호스팅 운영비(200평 초과): 가격 협의</p>
          <p style={{margin:"5px", background:"#fff"}}>💡 호스팅 최소 계약 기간: 3개월</p>
          <p style={{margin:"5px", background:"#fff"}}>💡 호스팅 연간 계약시 10개월로 계산</p>
          <p style={{margin:"5px", background:"#fff"}}>💡 VR 어플리케이션 컨텐츠 편집 비용 별도</p>
        </div>
      </section>
      <Faq />
    </>
  )
}

export default Pricing
