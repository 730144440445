export const homeAbout = [
  {
    id: 1,
    cover: "./images/icon/panorama.png",
    title: "메타버스/VR 기술을 활용한 가상 랜드마크 체험 서비스",
    desc: "메타버스/VR 기술을 활용한 가상 랜드마크 체험 서비스를 도입하여 사용자들에게 아이코닉한 명소를 실감나게 체험할 수 있는 기회를 제공합니다.",
  },
  {
    id: 1,
    cover: "./images/icon/map.png",
    title: "정적인 홍보 방식의 한계 극복",
    desc: "기존의 정적인 홍보 방식의 한계를 극복하기 위해 360도 파노라마 영상과 VR/AR 기술을 결합하여 생동감 있는 가상 체험을 구현합니다.",
  },
  {
    id: 1,
    cover: "./images/icon/money.png",
    title: "새로운 가치와 혜택 제공",
    desc: "이를 통해 지역민과 관광객은 지역 명소와 상권을 실감나게 체험할 수 있고, 기업은 혁신적인 홍보 수단으로 신규 매출 창출 기회를 얻을 수 있습니다.",
  },
]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "COURSES",
  },
]
export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "Introducing to Software Engineering",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by John Smith",
        totalTime: "50 lectures (190 hrs)",
      },
    ],
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Enhancing Adobe Photoshop CC 2020 Skills",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Ram Gurung",
        totalTime: "30 lectures (125 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$25 per month",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "HTML, CSS, and Javascript for Web Developers",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Saroj Nepal",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$50 All Course",
    pricePer: "$5 per month",
  },
  {
    id: 4,
    cover: "../images/courses/c4.png",
    coursesName: "Introducing to Programming with WordPress",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Prabin Thapa Mager",
        totalTime: "20 lectures (20 hrs)",
      },
    ],
    priceAll: "$30 All Course",
    pricePer: "$3 per month",
  },
  {
    id: 5,
    cover: "../images/courses/c5.png",
    coursesName: "Introducing to Programming with ReactJS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "100 lectures (150 hrs)",
      },
    ],
    priceAll: "$300 All Course",
    pricePer: "$30 per month",
  },
  {
    id: 6,
    cover: "../images/courses/c6.png",
    coursesName: "Learn Frontend Programming Language",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Sunil Bishowkaram",
        totalTime: "200 lectures (300 hrs)",
      },
    ],
    priceAll: "$500 All Course",
    pricePer: "$80 per month",
  },
  {
    id: 7,
    cover: "../images/courses/c7.png",
    coursesName: "Introducing to with HTML / CSS",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Price",
        totalTime: "20 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$2 per month",
  },
  {
    id: 8,
    cover: "../images/courses/c8.png",
    coursesName: "Introducing to with JAVA",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "80 lectures (200 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$40 per month",
  },
  {
    id: 9,
    cover: "../images/courses/c9.png",
    coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Petter",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$20 All Course",
    pricePer: "$3 per month",
  },
]
export const online = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "UI/UX Design Courses",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Art & Design",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Computer Science",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "History & Archeologic",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Software Engineering",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Information Software",
    course: "60 Courses",
  },
  {
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: "Health & Fitness",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o8.png",
    hoverCover: "./images/courses/online/o8.1.png",
    courseName: "Marketing",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "Graphic Design",
    course: "80 Courses",
  },
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Music",
    course: "120 Courses",
  },
  {
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Business Administration",
    course: "17 Courses",
  },
  {
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "Web Management",
    course: "17 Courses",
  },
]
export const team = [
  {
    cover: "./images/team/1712504862_1.png",
    name: "대표 서준호",
    work: "웹 및 모바일 어플리케이션 개발",
  },
  {
    cover: "./images/team/1712505423_1.png",
    name: "이사 김도형",
    work: "영상 촬영 및 편집",
  },
  {
    cover: "./images/team/1712505568_1.png",
    name: "팀장 금정수",
    work: "재무회계 및 고객관리",
  },
  {
    cover: "./images/team/1712506047_1.png",
    name: "팀원 이민정(예정)",
    work: "UI/UX 및 시각 디자인",
  },
]
export const price = [
  {
    name: "대구 전지역",
    price: "220,000",
    desc: ["대구 모든 지역","VAT 포함 가격","기본 20개 실내공간 태그","태그 추가시 협의","기본 2회 외부촬영","기본 1회 인터뷰 촬영"],
  },
  {
    name: "대구 인접도시",
    price: "330,000",
    desc: ["대구시청기준 100Km 반경","VAT 포함 가격","기본 30개 실내공간 태그","태그 추가시 협의","기본 1회 외부촬영","기본 1회 인터뷰 촬영"],
  },
  {
    name: "그외 지역",
    price: "440,000",
    desc: ["전국 시도","VAT 포함 가격","기본 30개 실내공간 태그","태그 추가시 협의","기본 1회 외부촬영","기본 1회 인터뷰 촬영"],
  },
  {
    name: "도서산간지역",
    price: "가격 협의",
    desc: ["제주 및 섬지역 등","VAT 포함 가격","기본 30개 실내공간 태그","태그 추가시 협의","기본 1회 외부촬영","기본 1회 인터뷰 촬영"],
  },
]
export const faq = [
  {
    title: "랜드마크를 탐색하는 방법은 무엇인가요?",
    desc: "랜드마크를 탐색하는 가장 간단한 방법은 VR/AR을 통하여 원하는 랜드마크를 선택하는 것입니다. 선택한 랜드마크의 가상 모델이 표시되며, 사용자는 이를 통해 랜드마크를 자세히 살펴볼 수 있습니다. VR/AR 기술을 통해 사용자는 실제로 방문하지 않고도 랜드마크를 탐색할 수 있습니다.",
  },
  {
    title: "우리 동네에 있는 유명한 명소를 VR/AR을 통해 탐험할 수 있나요?",
    desc: "관광 명소를 선택하고 해당 명소의 가상 모델을 탐험하여 현지에서 더욱 효율적으로 시간을 활용할 수 있습니다.",
  },
  {
    title: "VR/AR을 이용해서 역사적인 장소를 살펴볼 수 있나요?",
    desc: "역사적인 장소를 살펴보는 가장 좋은 방법은 VR/AR 통하여 역사적인 장소를 선택하는 것입니다. 선택한 장소의 가상 모델이 표시되며, 사용자는 이를 통해 역사적인 장소를 자세히 살펴볼 수 있습니다. VR/AR 기술을 활용하여 역사적인 장소에 대해 더 깊이 이해할 수 있습니다.",
  },
  {
    title: "상점과 카페도 확인할 수 있나요?",
    desc: "VR/AR을 활용하여 상권을 탐색하는 것은 매우 편리합니다. 사용자는 가게와 카페를 탐색하고, 다양한 상권 정보를 확인할 수 있습니다. 이를 통해 사용자는 실제로 방문하지 않고도 상권을 탐험할 수 있습니다.",
  },
  {
    title: "상점이나 카페의 실내를 가상으로 볼 수 있나요?",
    desc: "가상 현실에서 실제 공간을 탐험하여 메뉴나 상품을 살펴보고, 공간의 분위기를 미리 경험할 수 있습니다. 이를 통해 고객들의 방문을 유도할 수 있습니다.",
  },  
]
export const blog = [
  {
    id: 1,
    type: "admin",
    date: "수성구",
    com: "3 COMMENTS ",
    title: "칼라스퀘어",
    desc: "#대구 스타디움 #대구 쇼핑몰",
    cover: "../images/blog/colorsquare.webp",
    url:"https://handheld.netlify.app",
  },
  {
    id: 2,
    type: "admin",
    date: "중구",
    com: "3 COMMENTS ",
    title: "달성공원",
    desc: "#새벽시장 #동물원 #달공",
    cover: "../images/blog/dalseongpark.webp",
    url:"https://my.matterport.com/show/?m=ibzWESraYMF",
  },
  {
    id: 3,
    type: "user",
    date: "군위군",
    com: "10 COMMENTS ",
    title: "추가 예정",
    desc: "#VR/AR #랜드마크 #선정중",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "달성군",
    com: "5 COMMENTS ",
    title: "추가 예정",
    desc: "#VR/AR #랜드마크 #선정중",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 5,
    type: "user",
    date: "동구",
    com: "12 COMMENTS ",
    title: "추가 예정",
    desc: "#VR/AR #랜드마크 #선정중",
    cover: "../images/blog/b6.webp",
  },
  {
    id: 6,
    type: "user",
    date: "서구",
    com: "12 COMMENTS ",
    title: "추가 예정",
    desc: "#VR/AR #랜드마크 #선정중",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 7,
    type: "user",
    date: "남구",
    com: "12 COMMENTS ",
    title: "추가 예정",
    desc: "#VR/AR #랜드마크 #선정중",
    cover: "../images/blog/b6.webp",
  },
  {
    id: 8,
    type: "user",
    date: "북구",
    com: "12 COMMENTS ",
    title: "추가 예정",
    desc: "#VR/AR #랜드마크 #선정중",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 9,
    type: "admin",
    date: "달서구",
    com: "3 COMMENTS ",
    title: "추가 예정",
    desc: "#VR/AR #랜드마크 #선정중",
    cover: "../images/blog/b5.webp",
  },
]
export const testimonal = [
  {
    id: 1,
    name: "김영희",
    post: "대학생",
    desc: "VR 랜드마크 체험은 색다르고 재미있었어요. 집에서 가족과 함께 가상 여행을 즐길 수 있어서 좋았어요.",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "박성준",
    post: "직장인",
    desc: "바쁜 일상 속에서도 VR을 통해 랜드마크를 경험할 수 있어서 편리했고, 디테일과 실감나는 느낌이 인상적이었어요.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "이민호",
    post: "관광객",
    desc: "우연히 VR 프로그램을 체험했는데, 현지인처럼 랜드마크를 경험할 수 있어서 좋았어요. 다음에는 실제로 방문해보고 싶을 정도로 현실감 있었습니다.",
    cover: "./images/testo/t3.webp",
  },
]
