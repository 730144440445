import React, { useState, useEffect } from "react";
import { blog } from "../../dummydata";

const BlogCard = ({ filterValue }) => {
  const [filteredBlog, setFilteredBlog] = useState([]);
  const [visibleCount, setVisibleCount] = useState(4); // 보여지는 항목의 수를 상태로 관리

  // 필터링 함수
  const handleFilter = (value) => {
    const filtered = blog.filter((item) => item.date === value);
    setFilteredBlog(filtered);
  };

  // filterValue가 변경될 때마다 필터링 적용
  useEffect(() => {
    setVisibleCount(4);
    if (filterValue !== "") {
      handleFilter(filterValue);
    } else {
      setFilteredBlog(blog);
    }
  }, [filterValue]);

  // '더 보기' 버튼 클릭 시 보여지는 항목의 수를 4개 추가로 증가
  const handleShowMore = () => {
    setVisibleCount(visibleCount + 4);
  };

  // 블로그 항목을 클릭할 때 새 창으로 링크를 열어주는 함수
  const handleBlogClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      alert("해당 링크의 URL이 없습니다. 빠른 시일 내에 업데이트하겠습니다.\n저희 사이트를 찾아주셔서 감사합니다. - 코리아클래스 일동");
    }
  };

  return (
    <>
      {filteredBlog.slice(0, visibleCount).map((val) => (
        <div
          className="demoitems items shadow"
          style={{ width: "48%", float: "left", marginRight: "10px", cursor: "pointer" }} // 마우스 포인터 모양 변경
          key={val.id}
          onClick={() => handleBlogClick(val.url)} // 블로그 항목 클릭 시 링크 열기
        >
          <div className="img">
            <img src={val.cover} alt="" />
          </div>
          <div className="text">
            <div className="admin flexSB">
              <span>
                <i className="fa fa-user"></i>
                <label htmlFor="">{val.type}</label>
              </span>
              <span>
                <i className="fa fa-map-marker-alt"></i>
                <label htmlFor="">{val.date}</label>
              </span>
            </div>
            <h1>{val.title}</h1>
            <p>{val.desc}</p>
          </div>
        </div>
      ))}
      <div style={{clear:"both"}} />
      {/* '더 보기' 버튼 */}
      <div>
      {filteredBlog.length > visibleCount && (
        <button className="primary-btn" onClick={handleShowMore}>더 보기</button>
      )}
      </div>
    </>
  );
};

export default BlogCard;
