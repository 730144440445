import React from "react"
import { price } from "../../dummydata"

const PriceCard = () => {
  return (
    <>
      {price.map((val) => (
        <div className='items shadow'>
          <h4>{val.name}</h4>
          <h1>
            <span>₩</span>
            {val.price}
          </h1>
          <ul>
            {val.desc.map(describe => (
              <li style={{marginBottom:"15px", fontSize:"1.15rem"}}><span style={{color:"darkgreen"}}>&#x2714;</span> {describe}</li>
            ))}
          </ul>
          <button className='outline-btn'>선택하기</button>
        </div>
      ))}
    </>
  )
}

export default PriceCard
